<template>
  <v-container class="my-4">
    <v-row>
      <v-col cols="12" md="9">
        <v-row class="mb-2">
          <v-col cols="12"
            >
            <span v-if="!budgets"><h2>WaizmannTabelle AbrechnungsPlan abrufen</h2>
            Tragen Sie hier die Waizmann Tarif-Nr. ein, um den gewünschten
            AbrechnungsPlan abzurufen.
            <br />
            <br />
          </span>
            <v-row class="mb-2">
              <v-col cols="3">
                <v-text-field  @keyup.enter="getTarif" v-model="tarif_id" label="Waizmann Tarif-Nr.:" />
              </v-col>
              <v-col cols="8">
                <v-btn    size="large"
                color="primary" height="56px" @click="getTarif"
                  >jetzt AbrechnungsPlan abrufen</v-btn
                ></v-col
              >
            </v-row>
          </v-col>
        </v-row>
        <v-alert type="error" v-if="searchStarted && !budgets"
          ><h2 class="text-white">
            Für die Tarif.Nr.: {{ tarif_id }} konnte kein AbrechnungsPlan gefunden
            werden.
          </h2></v-alert
        >

        <div v-if="budgets">


          <v-row class="mb-2">
            <v-col cols="12">
              <h2>WaizmannTablle AbrechnungsPlan</h2>
              <h3 class="text-blue">
                {{ tarif.un_name }} {{ tarif.tarif_title }} | Nr.:
                {{ tarif.tarif_id }}
              </h3>
            </v-col>
          </v-row>
          <v-row class="mb-2">
            <v-col cols="12">
              <h3>
                Erstmalige Abrechnung:  <b class="text-blue">{{ wartezeitEnde }}</b>
                
              </h3>
              Die Zahnzusatzversicherung übernimmt erstmalig ab dem <b>{{ wartezeitEnde }}</b> die Kosten. 
<br>Professionelle Zahnreinigungen
werden ab dem <b>{{endeWZPzr}}</b> übernommen.
            </v-col>
          </v-row>

          <v-row class="mb-2">
            <v-col cols="12">
              <h3>
                Anfängliche Abrechnungsbudgets bei einem Versicherungsbeginn:
                <span class="text-blue">{{ budgetbeginn }}</span>
              </h3>
              Bei einem Vertragsbeginn am {{ budgetbeginn }} (
              {{ tarif.un_name }} {{ tarif.tarif_title }} | Nr.
              {{ tarif.tarif_id }}) stehen Ihnen in den ersten
              Versicherungsjahren folgende Abrechnungs-Budgets zur Verfügung.
            </v-col>
          </v-row>
          <div class="pa-4" style="background-color: #f2f2f2">
            <v-row class="mb-2">
              <v-col cols="12" md="4">
                <strong
                  >Anfängliche<br />
                  Abrechnungsbudgets</strong
                ><br />
                Bei Abschluss zum {{ budgetbeginn }} stehen Ihnen folgende
                Budgets zur Verfügung:
              </v-col>

              <v-col cols="12" md="8">
                <v-row>
                  <v-col
                    v-for="bereich in bereiche"
                    :key="bereich.value"
                    cols="4"
                  >
                    <b>{{ bereich.name }}-Budget</b>
                  </v-col>
                </v-row>

                <!-- Datenreihen -->
                <v-row v-for="(budget, index) in budgets" :key="index">
                  <v-col
                    v-for="bereich in bereiche"
                    :key="bereich.value"
                    cols="4"
                  >
                    <div
                      v-if="
                        budget[bereich.value] && budget[bereich.value].value
                      "
                    >
                      <span>{{ budget[bereich.value].value.datum }}: </span>
                      <b :style="{ color: budget[bereich.value].value.color }">
                        {{ budget[bereich.value].value.sum }}
                      </b>
                    </div>
                    <div v-else>
                      <span>&nbsp;</span>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

           
          </div>
          <v-row class="mt-2">
            <v-col cols="12">
              <h3 class="mb-2">Wichtige Abrechnungshinweise für      <span class="text-blue">{{ tarif.un_name }} {{ tarif.tarif_title }} | Nr.
                {{ tarif.tarif_id }}</span></h3>
                <div class="pa-4" style="background-color: #f2f2f2" >
                  <v-row v-if="tarif.tarif_leistung_goz == 1">
                    <v-col cols="6" >Erweiterte Abrechnung</v-col>
                    <v-col cols="6"><b class="text-green">Abrechnung bis GOZ Faktor:  {{ tarif.tarif_leistung_goz_beschreibung }}</b></v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="6">Preis-/Leistungsverzeichnis für Material- und Laborkosten</v-col>
                    <v-col cols="6">
                      <span v-if="tarif.tarif_leistung_plv == 0"><b class="text-green">Erstattung zu ortsüblichen Preisen.</b> Kein Preis-Leistungsverzeichnis.</span>
                      <span v-if="tarif.tarif_leistung_plv == 1">Die Obergrenzen für Material- und Laborkosten sind in Preis-Leistungsverzeichnis der Versicherung festgelegt. </span>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="6">Prophylaxe Abrechnung</v-col>
                    <v-col cols="6">
                      <span v-if="tarif.tarif_leistung_pzr_pauschal == 1"><b class="text-green">Prophylaxe-Behandlungen können vereinfacht pauschal abrechnen </b> </span>
                      <span v-if="tarif.tarif_leistung_pzr_pauschal == 0">Bitte rechnen Prophylaxe-Behandlungen nach GOZ 1040 ab. Eine pauschale Abrechnung wird nicht erstattet. </span>
                    </v-col>
                  </v-row>
                  <v-row v-if="tarif.tarif_leistung_pzr_ohne_begrenzung == 1">
                    <v-col cols="6">Prophylaxe Flatrate</v-col>
                    <v-col cols="6"><b class="text-green">Prophylaxe Flatrate | keine Begrenzung Anzahl PZR / Obergrenze </b></v-col>
                  </v-row>
                  <v-row v-if="tarif.tarif_leistung_pzr_ohne_begrenzung == 0">
                    <v-col cols="6">Prophylaxe Begrenzungen</v-col>
                    <v-col cols="6">Anzahl PZR-Behandlungen pro Jahr: <b>{{ tarif.tarif_pzr_anzahl }}</b>
                      <br>Maximaler Erstattungsbetrag pro PZR-Behandlung: <b>{{ tarif.tarif_pzr_summe }},- €</b>

                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">Begrenzung Verblendungen</v-col>
                    <v-col cols="6">
                      {{ tarif.tarif_beschreibung_verblendung_beg }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">Abrechnung OP-Mikroskop</v-col>
                    <v-col cols="6">
                     
                      {{ tarif.tarif_leistung_trend_opmikro }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">Abrechnung Laser</v-col>
                    <v-col cols="6">
                      {{ tarif.tarif_leistung_trend_laser }}
                    </v-col>
                  </v-row>
                </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="12" md="3"></v-col>
    </v-row>
  </v-container>
</template>
>

<script setup>
  import { ref,getCurrentInstance,onMounted } from "vue";
  import BaseCalls from "@/services/BaseCalls";
  
  // import StimmenRight from "./../components/site/StimmenRight.vue";
  import { useLoaderStore } from "@/stores/loaderStore";
  const loaderStore = useLoaderStore();

  // Zustandsvariablen als refs
  const tarif = ref({});
  const budgetbeginn = ref(false);
  const wartezeitEnde = ref(false);
  const endeWZPzr = ref(false);
  const budgets = ref(false);
  const bereiche = ref(false);
  const tarif_id = ref('');
  const searchStarted = ref(false);

  const appLoaded = ref(false); // Zustand der App, ob geladen oder nicht

  // Methode zum Setzen des Ladezustands
  function setLoading(loading) {
    loaderStore.setLoader(loading);
  }

  // Async-Funktion zum Abrufen von Tarifdaten
  async function getTarif() {
    if (!tarif_id.value || tarif_id.value == 0 || isNaN(tarif_id.value)) {
      return;
    }
    console.log("getTarif");
    const select = {
      action: "getTarifBudgetComplete",
      tarif_id: tarif_id.value,
    };
    setLoading(true);
    searchStarted.value = false;
    try {
      const resp = await BaseCalls.postTarif(select);
      if (resp.data && resp.data.tarif) {
        console.log( resp.data);
        console.log("getTarifSuccess");
        tarif.value = resp.data.tarif;
        budgetbeginn.value = resp.data.budgetbeginn;
        budgets.value = resp.data.budgets;
        endeWZPzr.value = resp.data.tarif.endeWZPzr;
        wartezeitEnde.value = resp.data.tarif.wartezeitEnde;
        bereiche.value = resp.data.bereiche;
        setLoading(false);
        appLoaded.value = true;
        // Aktualisiere Dokumenten-Head, Implementierung hängt von deinem Projekt ab
        // proxy.$updateDocumentHead(tarif.value.titleTag, tarif.value.descTag);
      } else {
        setLoading(false);
        searchStarted.value = true;
      }
    } catch (err) {
      console.log("error berechnung");
      setLoading(false);
    }
  }

  onMounted(() => {
    const { proxy } = getCurrentInstance();
    proxy.$updateDocumentHead(
      "WaizmannTabelle BudgetPlan abrufen",
      "Wieviel Abrechnungsbudget steht in den ersten Jahren zur Verfügung?"
    );
  });

  // Tarifdaten beim Mounten der Komponente abrufen
</script>
